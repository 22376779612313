<template>
  <legal-page-template :locale="locale">
    <main class="legal-page__content legal-page-content">
      <h1 class="legal-page-content__title">{{ $t('legal.legalPage.title') }}</h1>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.legalPage.publisher') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.universkinSimplifiedJoinStock') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.representedByItsCEO') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.communityVATNumber') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.legalPage.publicationAndEditorialBranch') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.directorOfPublication') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.inRespectOfExercise') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.legalPage.hosting') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.siteIsHostedBy') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.legalPage.credits') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.websiteDesign') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.realizationOfSite') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.propertySite') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__title">
          {{ $t('legal.legalPage.intellectualProperty') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.universkinOwnsIntellectualProperty') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.asSuchUniverskinOwnsDomain') }}
          </p>

          <i18n
            tag="p"
            path="legal.legalPage.moreoverUniverskinHolds"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.legalPage.europeanUnionBrand') }}</b>
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.accessToServices') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.legalPage.consequentlyAccessToServices') }}
          </p>
        </div>
      </div>
    </main>
  </legal-page-template>
</template>

<script>
import LegalPageTemplate from '@/components/templates/LegalPageTemplate';

export default {
  name: 'PageLegal',
  components: { LegalPageTemplate },
  props: {
    locale: {
      type: String,
      default: ''
    }
  },
  metaInfo: {
    title: 'Legal',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-pages/content-styles';
</style>
